import React, { useMemo } from 'react'

import { Typography } from '@mui/material'
import { ArrowForwardIcon, Box, ButtonV2, Link } from '@vividseats/vivid-ui-kit'
import classNames from 'classnames'

import SearchBar from '@/components/shared/searchbar'
import { PATHS } from '@/constants'
import { SuggestionsProvider } from '@/context/suggestions'
import useWindowSize, { isTypeDesktop } from '@/hooks/use-window-size'
import { DeviceType } from '@/hooks/use-window-size/types'
import { trackHomePageEvent } from '@/utils/analytics'
import { HOME_PAGE_HERO_GET_TICKETS_CLICK_LOCATION } from '@/utils/analytics/types'

import type { GeolocatedHeroData } from '../../types'

import styles from './styles.module.scss'
import { BACKGROUND_IMAGE_SIZE, SEARCH_BAR_WIDTH_THRESHOLD } from './types'
import { getNflBackgroundImage, getNflPerformerUrl, getNflPerformerName, getNflSecondCta } from './utils'

interface Props {
    geolocatedData?: GeolocatedHeroData
}

const GeolocatedHomePageHero: React.FC<Props> = ({ geolocatedData }) => {
    const { type, width } = useWindowSize()
    const isDesktop = isTypeDesktop(type)
    const showSearchBar = width < SEARCH_BAR_WIDTH_THRESHOLD
    const eventIds = geolocatedData?.eventIds || []
    const name = geolocatedData?.name || ''

    const performerCount = eventIds.length || 0
    const mainPerformer = eventIds[0]
    const images = getNflBackgroundImage(geolocatedData)
    const backgroundImageSM = images.get(BACKGROUND_IMAGE_SIZE.SM)
    const backgroundImageMD = images.get(BACKGROUND_IMAGE_SIZE.MD)
    const backgroundImageLG = images.get(BACKGROUND_IMAGE_SIZE.LG)
    const backgroundImageXL = images.get(BACKGROUND_IMAGE_SIZE.XL)

    const title = useMemo(() => {
        if (performerCount > 1) return 'Countdown to kickoff!'
        if (performerCount === 1) return `Score ${name} Tickets!`
        return 'Score seats to the biggest NFL games!'
    }, [performerCount, name])

    const subTitle = performerCount > 0 ? 'Buy Tickets, Earn Rewards' : 'Buy NFL Tickets, Earn Rewards'

    const ctaHref = getNflPerformerUrl(mainPerformer)
    const ctaText = useMemo(() => {
        if (performerCount > 1) {
            return type == DeviceType.MOBILE
                ? `${getNflPerformerName(mainPerformer)} Tickets`
                : `Score ${getNflPerformerName(mainPerformer)} Tickets`
        }
        if (performerCount === 1) return `Get ${name} Tickets`

        return 'Get Tickets'
    }, [type, name, performerCount, mainPerformer])

    const mergedContainer = classNames(styles.container, { [styles.withTwoPerformers]: performerCount === 2 })
    const mergedInnerContainer = classNames(styles.innerContainer, {
        [styles.isCommanders]: name === 'Commanders',
    })

    const mergedTitle = classNames(
        styles.title,
        { [styles.withTwoPerformers]: performerCount === 2 },
        { [styles.withShortName]: name && name.length < 7 },
        { [styles.withLongName]: name.length >= 10 || !name },
    )
    const mergedSubtitle = classNames(styles.subtitle, { [styles.withTwoPerformers]: performerCount === 2 })
    const mergedCtaContainer = classNames(styles.ctaContainer, { [styles.withTwoPerformers]: performerCount === 2 })

    const trackCtaClick = (event: any) => {
        if (!event.target.innerHTML) return
        trackHomePageEvent({
            click_location: HOME_PAGE_HERO_GET_TICKETS_CLICK_LOCATION,
            click_text: event.target.innerHTML,
        })
    }

    const SecondPerformerCta = () => {
        if (!geolocatedData) return null
        const cta = getNflSecondCta(geolocatedData, type == DeviceType.MOBILE)

        return cta ? (
            <ButtonV2
                className={styles.secondPerformerCta}
                data-testid="cta-button"
                size={isDesktop ? 'large' : 'medium'}
                importance="secondary"
                href={cta.href}
                onClick={trackCtaClick}
            >
                {cta.text}
            </ButtonV2>
        ) : null
    }

    const FindCta = () => {
        if (performerCount === 0) return null
        if (performerCount === 1) {
            return (
                <Box className={styles.findCtaContainer}>
                    <ButtonV2
                        className={styles.findCta}
                        data-testid="cta-button"
                        size={isDesktop ? 'large' : 'medium'}
                        importance="secondary"
                        href={PATHS.NFL_SUB_CATEGORY}
                        onClick={trackCtaClick}
                    >
                        Find Your Favorite Team
                    </ButtonV2>
                </Box>
            )
        }
        return (
            <Box className={styles.findCtaContainer}>
                <Link
                    className={styles.findLinkWithIcon}
                    data-testid="cta-button"
                    size="large"
                    importance="tertiary"
                    href={PATHS.NFL_SUB_CATEGORY}
                    onClick={trackCtaClick}
                    legacyBehavior
                >
                    Find Your Favorite Team <ArrowForwardIcon />
                </Link>
            </Box>
        )
    }

    const PerformerLinks = () => (
        <Box className={mergedCtaContainer}>
            {ctaHref ? (
                <ButtonV2
                    className={styles.cta}
                    data-testid="cta-button"
                    size={isDesktop ? 'large' : 'medium'}
                    importance="secondary"
                    href={ctaHref}
                    onClick={trackCtaClick}
                >
                    {ctaText}
                </ButtonV2>
            ) : null}
            {performerCount === 2 ? <SecondPerformerCta /> : null}
            <FindCta />
        </Box>
    )

    const backgroundImage =
        type == DeviceType.MOBILE
            ? backgroundImageSM
            : type == DeviceType.TABLET
              ? backgroundImageMD
              : type == DeviceType.DESKTOP
                ? backgroundImageLG
                : backgroundImageXL

    return (
        <div className={styles.containerGradient}>
            <Box
                className={mergedContainer}
                mobileDisplay={type == DeviceType.MOBILE ? 'block' : 'none'}
                tabletDisplay={type == DeviceType.TABLET ? 'block' : 'none'}
                desktopDisplay={type == DeviceType.DESKTOP ? 'block' : 'none'}
                desktopXlDisplay={type == DeviceType.DESKTOP_XL ? 'block' : 'none'}
                style={{ backgroundImage: `url(${backgroundImage})` }}
                data-testid="container-box"
            >
                <div className={mergedInnerContainer}>
                    {showSearchBar && (
                        <SuggestionsProvider>
                            <SearchBar isFullWidth={isDesktop} />
                        </SuggestionsProvider>
                    )}
                    <div className={styles.content}>
                        <Typography
                            component="h1"
                            color="white.100"
                            variant="title-lg"
                            className={mergedTitle}
                            data-testid="title"
                        >
                            {title}
                        </Typography>
                        <Typography
                            className={mergedSubtitle}
                            color="white.100"
                            variant="inherit"
                            data-testid="subtitle"
                        >
                            {subTitle}
                        </Typography>
                        <PerformerLinks />
                    </div>
                </div>
            </Box>
        </div>
    )
}

export default GeolocatedHomePageHero
