import type { $FC } from 'react'
import React, { createContext } from 'react'

import { serverSideQuery, useQuery } from '@/api'
import { CATALOG_ASSET_ENDPOINT, DEFAULT_CONTEXT_VALUES } from '@/constants'

import { getImagesByPerformer, imagesSelector } from './selectors'
import type {
    CatalogAssetContextType,
    CatalogAssetDataType,
    CatalogAssetParams,
    CatalogAssetProviderProps,
} from './types'

const context = createContext<CatalogAssetContextType>(DEFAULT_CONTEXT_VALUES)
const { Provider } = context

export const CatalogAssetProvider: $FC<CatalogAssetProviderProps> = ({
    children,
    initialFetchDisabled,
    initialParams,
    initialProps,
}) => {
    const { data, error, fetching } = useQuery<CatalogAssetDataType>({
        endpoint: CATALOG_ASSET_ENDPOINT,
        params: initialParams,
        initialFetchDisabled,
        initialData: initialProps?.data,
    })

    const state = { data, error, fetching }

    return (
        <Provider
            value={{
                state,
                selectors: [imagesSelector(state), getImagesByPerformer(state)],
            }}
        >
            {children}
        </Provider>
    )
}

export const serverSideRead = serverSideQuery<CatalogAssetDataType, CatalogAssetParams>(CATALOG_ASSET_ENDPOINT)
export default context
