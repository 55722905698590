import React from 'react'

import { Typography } from '@mui/material'
import type { WithOptimizelyProps } from '@optimizely/react-sdk'
import { withOptimizely } from '@optimizely/react-sdk'
import { Box, Card, CardBody, CardImage, HorizontalScroll, Link } from '@vividseats/vivid-ui-kit'

import { OPTIMIZELY_EVENT } from '@/optimizely/constants'
import { trackHomePageEvent } from '@/utils/analytics'
import { ClickLocation } from '@/utils/analytics/types'

import { TOP_HOME_PAGE_CATEGORIES } from './constants'
import styles from './styles.module.scss'

const BrowseTopCategories: React.FC<WithOptimizelyProps> = ({ optimizely }) => {
    const handleTileClickEventTracking = (title: string) => {
        trackHomePageEvent({
            click_location: ClickLocation.HOME_PAGE_SHOP_OUR_TOP_CATEGORIES,
            click_text: title,
        })

        optimizely?.track(OPTIMIZELY_EVENT.browseCategoriesClick)
    }

    return (
        <div className={styles.container} data-testid="browse-top-categories">
            <Typography component="h2" variant="title-xl" className={styles.title}>
                Shop Our Top Categories
            </Typography>
            <Box
                display="flex"
                mobileJustify="between"
                tabletJustify="between"
                mobileAlign="center"
                tabletAlign="center"
            >
                <HorizontalScroll
                    scroll={false}
                    className={styles.scrollContainer}
                    classNameForHsWrapper={styles.scrollWrapper}
                >
                    {TOP_HOME_PAGE_CATEGORIES.map((category) => {
                        return (
                            <Link
                                href={category.link}
                                isExternal={false}
                                className={styles.link}
                                key={category.title}
                                target="_blank"
                                onClick={() => handleTileClickEventTracking(category.title)}
                                data-testid="category-tile"
                                legacyBehavior
                            >
                                <Card className={styles.card}>
                                    <CardImage
                                        desktopImage={category.imgLink}
                                        externalImage={category.imgLink}
                                        externalImageAlt={category.title}
                                        mobileImage={category.imgLink}
                                    />
                                    <CardBody>
                                        <Typography
                                            component="h3"
                                            variant="title-md"
                                            color="white.100"
                                            className={styles.cardText}
                                        >
                                            {category.title}
                                        </Typography>
                                    </CardBody>
                                </Card>
                            </Link>
                        )
                    })}
                </HorizontalScroll>
            </Box>
        </div>
    )
}

export default withOptimizely(BrowseTopCategories)
