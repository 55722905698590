import React from 'react'

import { Typography } from '@mui/material'
import type { WithOptimizelyProps } from '@optimizely/react-sdk'
import { withOptimizely } from '@optimizely/react-sdk'
import { ArrowForwardIcon, Box, ButtonV2, Link } from '@vividseats/vivid-ui-kit'
import classNames from 'classnames'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'

import SearchBar from '@/components/shared/searchbar'
import { PATHS, TRENDING_CATEGORY_WIDTH_THRESHOLD } from '@/constants'
import useWindowSize, { isTypeDesktop } from '@/hooks/use-window-size'
import { DeviceType } from '@/hooks/use-window-size/types'
import { useTrendingCategoryFeature } from '@/optimizely/features/trending-category-feature'
import { useHomePageDynamicContent } from '@/optimizely/home-page-dynamic-content/hooks/use-home-page-dynamic-content'
import { trackHomePageEvent } from '@/utils/analytics'
import { ClickLocation } from '@/utils/analytics/types'

import styles from './styles.module.scss'

const DELIMITER = '<br/>'

export const DEFAULT_VARIABLES = {
    titleSM: 'Your ticket to more tickets.',
    titleMD: `Your ticket to${DELIMITER}more tickets.`,
    subtitleSM: 'Score tickets to your favorite live sports, concerts, theater and comedy shows.',
    subtitleMD: 'Score tickets to your favorite live sports, concerts, theater and comedy shows.',
    ctaSM: 'Learn more',
    ctaMD: 'Learn more on how to earn',
    ctaHref: PATHS.REWARDS,
    backgroundImageSM: 'https://a.vsstatic.com/hero/home/custom/sports-blue/home-blue-desktop.jpg',
    backgroundImageMD: 'https://a.vsstatic.com/hero/home/custom/sports-blue/home-blue-desktop.jpg',
    backgroundImageLG: 'https://a.vsstatic.com/hero/home/custom/sports-blue/home-blue-desktop.jpg',
    backgroundImageXL: 'https://a.vsstatic.com/hero/home/custom/sports-blue/home-blue-desktop.jpg',
    cutOutImage: 'https://a.vsstatic.com/hero/home/custom/sports-blue/fan-cut-out.png',
    displayButton: false,
    slimHero: false,
    centerSearch: false,
    categoryButtons: false,
    geolocated: false,
}

const HomePageHero: NextPage<WithOptimizelyProps> = ({ optimizely }) => {
    const [isHomePageDynamicContentEnabled, homePageDynamicContentVariables] = useHomePageDynamicContent(optimizely)
    const [isTrendingCategoryEnabled] = useTrendingCategoryFeature()
    const { width, type } = useWindowSize()
    const isDesktop = isTypeDesktop(type)
    const isMediumDesktopWithTrending =
        isTrendingCategoryEnabled && isDesktop && width <= TRENDING_CATEGORY_WIDTH_THRESHOLD
    const router = useRouter()
    const {
        titleSM,
        titleMD,
        subtitleSM,
        subtitleMD,
        ctaSM,
        ctaMD,
        ctaHref,
        backgroundImageSM,
        backgroundImageMD,
        backgroundImageLG,
        backgroundImageXL,
        cutOutImage,
        displayButton,
        slimHero,
        centerSearch,
        categoryButtons,
    } =
        isHomePageDynamicContentEnabled && Object.keys(homePageDynamicContentVariables).length
            ? (homePageDynamicContentVariables as typeof DEFAULT_VARIABLES)
            : DEFAULT_VARIABLES
    const mergedContainer = classNames(
        styles.container,
        { [styles.dynamicContent]: isHomePageDynamicContentEnabled },
        { [styles.dynamicContentSlim]: slimHero },
    )
    const mergedGraphic = classNames(
        styles.graphic,
        { [styles.dynamicContent]: isHomePageDynamicContentEnabled },
        { [styles.dynamicContentSlim]: slimHero },
    )
    const mergedTitle = classNames(
        styles.title,
        { [styles.dynamicContent]: isHomePageDynamicContentEnabled },
        { [styles.dynamicContentSlim]: slimHero },
        { [styles.withCategoryButtons]: categoryButtons },
    )
    const mergedSubtitle = classNames(
        styles.subtitle,
        { [styles.dynamicContent]: isHomePageDynamicContentEnabled },
        { [styles.dynamicContentSlim]: slimHero },
        { [styles.centerSearch]: centerSearch },
        { [styles.withCategoryButtons]: categoryButtons },
    )
    const mergedActionLink = classNames(
        styles.actionLink,
        { [styles.dynamicContent]: isHomePageDynamicContentEnabled },
        { [styles.dynamicContentSlim]: slimHero },
        { [styles.withCategoryButtons]: categoryButtons },
    )
    const mergedLinkDetail = classNames(
        styles.linkDetail,
        { [styles.dynamicContent]: isHomePageDynamicContentEnabled },
        { [styles.dynamicContentSlim]: slimHero },
    )
    const mergedInnerContainer = classNames(styles.innerContainer, {
        [styles.centerSearch]: centerSearch,
        [styles.withCategoryButtons]: categoryButtons,
    })

    const buttonText = type == DeviceType.MOBILE ? ctaSM : ctaMD
    const trackNavClick = () => {
        trackHomePageEvent({
            click_location: ClickLocation.HERO_GET_TICKETS,
            click_text: buttonText,
        })
    }

    const heroButtonLink = (
        <Box className={mergedActionLink}>
            {ctaHref ? (
                displayButton ? (
                    <ButtonV2
                        data-testid="cta-button"
                        size="large"
                        importance="secondary"
                        href={ctaHref}
                        onClick={trackNavClick}
                    >
                        {buttonText}
                    </ButtonV2>
                ) : (
                    <Link href={ctaHref} target="_self" importance="none" onClick={trackNavClick} legacyBehavior>
                        <div className={mergedLinkDetail} data-testid="cta-link">
                            <Typography>{buttonText}</Typography>
                            <ArrowForwardIcon fontSize="1.5rem" />
                        </div>
                    </Link>
                )
            ) : (
                <div className={mergedLinkDetail} data-testid="cta-text">
                    <Typography>{buttonText}</Typography>
                </div>
            )}
        </Box>
    )

    const renderCategoryButtons = (isMobile: boolean) => (
        <Box className={styles.categoryButtons}>
            <ButtonV2
                data-testid="hero-sports-button"
                importance="secondary"
                size={isMobile ? 'medium' : 'large'}
                href="/sports"
            >
                Shop Sports
            </ButtonV2>
            <ButtonV2
                data-testid="hero-concerts-button"
                importance="secondary"
                size={isMobile ? 'medium' : 'large'}
                href="/concerts"
            >
                Shop Concerts
            </ButtonV2>
            <ButtonV2
                data-testid="hero-theater-button"
                importance="secondary"
                size={isMobile ? 'medium' : 'large'}
                href="/theater"
            >
                Shop Theater
            </ButtonV2>
            <ButtonV2
                data-testid="hero-comedy-button"
                importance="secondary"
                size={isMobile ? 'medium' : 'large'}
                href="/comedy"
            >
                Shop Comedy
            </ButtonV2>
        </Box>
    )

    const mobileHeroContent = (
        <div className={styles.mobile}>
            <Typography component="h1">
                {titleSM.split(DELIMITER).map((line: string, idx: number) => {
                    return (
                        <Typography
                            color="white.100"
                            variant="title-lg"
                            key={`dynamic-title-${idx}`}
                            className={mergedTitle}
                            component="span"
                        >
                            {line}
                        </Typography>
                    )
                })}
            </Typography>

            <Typography color="white.100" className={mergedSubtitle}>
                {subtitleSM}
            </Typography>

            {categoryButtons && renderCategoryButtons(true)}
            {ctaSM && heroButtonLink}
        </div>
    )

    const tabletAndDesktopHeroContent = (
        <div className={styles.tabletAndDesktop}>
            <Typography component="h1">
                {titleMD.split(DELIMITER).map((line: string, idx: number) => {
                    return (
                        <Typography
                            variant="title-lg"
                            color="white.100"
                            key={`dynamic-title-${idx}`}
                            className={mergedTitle}
                            component="span"
                        >
                            {line}
                        </Typography>
                    )
                })}
            </Typography>

            <Typography color="white.100" className={mergedSubtitle}>
                {subtitleMD}
            </Typography>

            {categoryButtons && renderCategoryButtons(false)}
            {ctaMD && heroButtonLink}
        </div>
    )

    const cutOutImageContent = cutOutImage && (
        <div
            className={styles.cutOutImage}
            // Putting image as style for faster rendering and improve LCP score
            style={{ backgroundImage: `url(${cutOutImage})` }}
        />
    )

    const backgroundImage =
        type == DeviceType.MOBILE
            ? backgroundImageSM
            : type == DeviceType.TABLET
              ? backgroundImageMD
              : type == DeviceType.DESKTOP
                ? backgroundImageLG
                : backgroundImageXL

    const handleHeroClick = async (e: React.MouseEvent<HTMLElement>) => {
        // ignore clicks that come from links/buttons
        if (e.target === e.currentTarget) {
            await router.push(ctaHref)
        }
    }

    return (
        <div className={styles.containerGradient} data-testid="homepage-hero-container">
            <Box
                className={mergedContainer}
                mobileDisplay={type == DeviceType.MOBILE ? 'block' : 'none'}
                tabletDisplay={type == DeviceType.TABLET ? 'block' : 'none'}
                desktopDisplay={type == DeviceType.DESKTOP ? 'block' : 'none'}
                desktopXlDisplay={type == DeviceType.DESKTOP_XL ? 'block' : 'none'}
                style={{ backgroundImage: `url(${backgroundImage})` }}
                onClick={ctaHref ? handleHeroClick : undefined}
            >
                <div className={mergedGraphic} onClickCapture={ctaHref ? handleHeroClick : undefined}>
                    <div className={mergedInnerContainer} onClickCapture={ctaHref ? handleHeroClick : undefined}>
                        {!centerSearch && (!isDesktop || isMediumDesktopWithTrending) && (
                            <SearchBar isFullWidth={isDesktop} />
                        )}
                        {type == DeviceType.MOBILE ? mobileHeroContent : tabletAndDesktopHeroContent}
                        {cutOutImageContent}
                        {centerSearch && <SearchBar isCenteredInHero={isDesktop} />}
                    </div>
                </div>
            </Box>
        </div>
    )
}

export default withOptimizely(HomePageHero)
