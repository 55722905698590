import type { FC } from 'react'
import React, { useContext } from 'react'

import { Typography } from '@mui/material'
import { ArrowForwardIcon, Column, HorizontalScroll, LazyBackgroundImage, Link } from '@vividseats/vivid-ui-kit'
import NextLink from 'next/link'

import NoSsr from '@/components/shared/no-ssr'
import { PATHS } from '@/constants'
import cmsHomeContext from '@/context/cms-home'
import type { BlogArticle } from '@/context/cms-home/types'
import { trackHomePageEvent } from '@/utils/analytics'
import { ClickLocation } from '@/utils/analytics/types'

import styles from './styles.module.scss'

const BLOG_IMG_URL = 'https://media.vsstatic.com/image/upload/v1667332442/hero/homepage/home-blog-bg.jpg'

const trackBlogClick = (title: string, index: number) => {
    trackHomePageEvent({
        click_location: ClickLocation.HOME_PAGE_BLOG,
        click_text: title,
        click_tile_location: index + 1,
        carousel_location: 3,
    })
}

const Blog: FC = () => {
    const { state } = useContext(cmsHomeContext)

    return (
        <NoSsr>
            <div className={styles.blogContainer}>
                <LazyBackgroundImage url={BLOG_IMG_URL} className={styles.blogImage}>
                    <Column desktop={true} mobile={false}>
                        <Link href={PATHS.BLOG} target="_self" importance="none" className={styles.moreNews}>
                            <div className={styles.linkDetail}>
                                <Typography variant="inherit">More Event News&nbsp;</Typography>
                                <ArrowForwardIcon fontSize="1.5rem" />
                            </div>
                        </Link>
                    </Column>

                    <HorizontalScroll scroll={false}>
                        {state.data?.blog_article.map((article: BlogArticle, index: number) => (
                            <Column className={styles.item} key={index}>
                                <Typography variant="caption-bold" className={styles.category}>
                                    {article.category_name}
                                </Typography>
                                <Typography variant="title-lg" className={styles.articleTitle}>
                                    {article.title}
                                </Typography>
                                <Link
                                    LinkComponent={NextLink}
                                    href={article.link}
                                    target="_self"
                                    importance="none"
                                    data-testid={`blog-link-${index}`}
                                    onClick={() => trackBlogClick(article.title, index)}
                                    legacyBehavior
                                >
                                    <div className={styles.linkDetail}>
                                        <Typography variant="body-medium">Read More&nbsp;</Typography>
                                        <ArrowForwardIcon fontSize="1.5rem" />
                                    </div>
                                </Link>
                            </Column>
                        ))}
                    </HorizontalScroll>
                </LazyBackgroundImage>
            </div>
        </NoSsr>
    )
}

export default Blog
